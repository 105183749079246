@font-face {
  font-family: "LemonBold";
  src: url("../fonts/LemonMilkBold.otf");
}
@font-face {
  font-family: "LemonRegular";
  src: url("../fonts/LemonMilkRegular.otf");
}

@font-face {
  font-family: "LemonMedium";
  src: url("../fonts/LemonMilkMedium.otf");
}

.dev-title:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 10px;
  }

  .home {
    text-align: center;
    line-height: 1.6;
  }

  .red {
    color: #ff3366;
  }

  .home-top {
    padding-top: 12vh;
    height: 90vh;
    background-color: #011f4c;
    color: white;
    font-family: "LemonBold";
    font-size: min(4.5vw, 1rem);
    line-height: 3rem;
  }

  .enigma {
    margin-top: 10vh;
  }

  .indicator::before {
    animation: bounce 1.4s ease infinite;
    bottom: 111vh;
    color: #fff;
    content: "╲╱";
    font-size: 2rem;
    height: 10vh;
    left: 50%;
    letter-spacing: -1px;
    line-height: 4rem;
    margin-left: -3rem;
    opacity: 0.8;
    position: absolute;
    text-align: center;
    width: 6rem;
  }

  @keyframes bounce {
    50% {
      transform: translateY(-50%);
    }
  }

  .links {
    display: none;
  }

  .home-bottom {
    height: 90vh;
    font-family: "LemonBold";
    color: #011f4c;
    line-height: 2;
  }

  .job-title {
    margin-top: 15vh;
    margin-left: 10vw;
    margin-right: 10vw;
    font-size: min(4vw, 1rem);
    margin-bottom: 20vh;
  }

  .route {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .btn {
    font-family: "LemonBold";
    background-color: #011f4c;
    color: white;
    border: none;
    margin-bottom: 2vh;
    cursor: pointer;
    width: 39vw;
    /* height: 7vh; */
    height: 7vh;
    font-size: 4.5vw;
  }

  .btn:hover {
    background-color: #ff3366;
  }
}

@media screen and (min-width: 769px) {
  body {
    font-size: 10px;
  }

  .home {
    display: flex;
    text-align: center;
    line-height: 1.6;
    height: 100vh;
  }

  .red {
    color: #ff3366;
  }

  .home-top {
    height: 100vh;
    width: 50%;
    background-color: #011f4c;
    color: white;
    font-family: "LemonBold";
    font-size: min(4.5vw, 1rem);
    line-height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .dev-title {
    margin-top: 20px;
    font-size: 24px;
    height: 40vh;
  }

  .indicator {
    display: none;
  }

  .links {
    display: flex;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
  }

  .links a {
    text-decoration: none;
    color: #fff;
  }

  .links a:hover {
    color: #ff3366;
  }

  .home-bottom {
    font-family: "LemonBold";
    color: #011f4c;
    line-height: 1.8;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .job-title {
    font-size: 24px;
  }

  .route {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    height: 40vh;
  }

  .btn {
    font-family: "LemonBold";
    background-color: #011f4c;
    color: white;
    border: none;
    cursor: pointer;
    width: 190px;
    /* height: 52px; */
    height: 65px;
    font-size: 25px;
  }

  .btn:hover {
    background-color: #ff3366;
  }
}
