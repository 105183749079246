@media  screen and (min-width:769px) {
    .navbar-mobile{
        display: none;
    }

    .sidebarmobile{
        display: none;
    }
}

@media  screen and (max-width:768px) {
    .desktop{
        display: none;
    }

    .sidebar{
        display: none;
    }

    .projectslist{
        margin-left: 20vw;
    }
}
