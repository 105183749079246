.navbar-mobile{
    z-index: 4;
    height: 100vh;
    width: min(20vw, 55px);
    background-color: #011f4c;
    position: fixed;
    text-align: center;
  }
  
  .burger div{
    width: min(7vw, 25px);
    height: 3px;
    background-color: white;
    margin: 6px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .burger:hover{
    cursor: pointer;
  }
  
  .burger{
    margin-top: 3vh;
  }
  
  a{
    text-decoration: none;
  }
  
  .name:hover{
    cursor: pointer;
  }
  
  .namemobile h1{
    margin-top: 3vh;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    color: white;
    font-family: "LemonBold";
    font-size: min(5vh, 40px);
    margin-right: auto;
    margin-left: auto;
  }
  
  .last-name{
    display: none;
  }
  
  .namemobile span{
    color: #ff3366;
  }

  .navbar-mobile img{
    margin-top: 10px;
    height: min(20vw, 45px);
    width: min(20vw, 45px);
  }