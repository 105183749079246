@media screen and (min-width: 769px) {
  .project {
    width: 50vw;
    position: relative;
    margin-top: 50px;
    margin-bottom: 300px;

    .info {
      position: relative;
      background-color: #011f4c;
      width: 50vw;
      height: 50vh;
      border-radius: 20px;
      h5 {
        margin-left: 0;
        margin-bottom: 0;
        padding: 20px;
        font-size: 25px;
        color: white;
      }
      h5:hover {
        color: #ff3366;
      }
      p {
        font-family: "LemonRegular";
        padding-left: 20px;
        width: 70%;
        color: white;
        font-size: 13px;
        margin-top: 0;
        line-height: 20px;
      }
      .mvp {
        text-decoration: underline;
        color: #ff3366;
        display: flex;
      }
    }

    .pic1 {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: -15%;
      right: -5%;
      z-index: 1;
      img {
        width: auto;
        height: 100%;
        border: 3px solid black;
        border-radius: 30px;
      }
    }
    .twt {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: -25%;
      right: -25%;
      z-index: 1;
      img {
        width: auto;
        height: 100%;
        border: 3px solid black;
        border-radius: 30px;
      }
    }
    .suit {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: -25%;
      right: -25%;
      z-index: 1;
      img {
        width: auto;
        height: 100%;
        // border: 3px solid black;
        border-radius: 30px;
      }
    }
    .thin {
      top: -30%;
      right: -10%;
      z-index: 2;
    }
    .yt {
      bottom: -50%;
      left: -10%;
      position: absolute;
      z-index: 2;
      iframe {
        width: 45vw;
        height: 50vh;
        border: 3px solid black;
        // border-radius: 30px;
      }
    }
    .pic2 {
      bottom: -50%;
      left: -10%;
      position: absolute;
      img {
        width: 45vw;
        height: auto;
        border: 3px solid black;
        // border-radius: 30px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .project {
    margin-top: 100px;
    position: relative;
    margin-bottom: 220px;
    img:hover {
      transform: none;
    }
    .info {
      background-color: white;
      color: #011f4c;
      width: 70vw;
      height: 20vh;
      border-radius: 20px;
      h5 {
        padding-top: 10px;
        margin-left: 8px;
        margin-bottom: 0;
        font-size: 15px;
        text-decoration: underline;
      }
      p {
        font-family: "LemonRegular";
        width: 60%;
        font-size: 10px;
        margin-top: 5px;
        margin-left: 8px;
      }
    }

    .pic1 {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: -50%;
      right: -20%;

      img {
        height: auto;
        width: 60%;
        border: 3px solid black;
        border-radius: 30px;
      }
    }

    .twt {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: -50%;
      right: -56%;

      img {
        height: auto;
        width: 40%;
        border: 3px solid black;
        border-radius: 30px;
      }
    }

    .suit {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: -40%;
      right: -65%;

      img {
        height: auto;
        width: 40%;
        border-radius: 30px;
      }
    }

    .thin {
      top: -30%;
      right: -32%;
    }

    .yt {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: 50%;
      left: -5%;
      position: absolute;
      z-index: 1;
      iframe {
        height: auto;
        width: auto;
        border: 3px solid black;
        border-radius: 30px;
      }
    }

    .pic2 {
      top: 50%;
      left: -5%;
      position: absolute;
      z-index: 1;
      img {
        width: auto;
        height: 15vh;
        border: 3px solid black;
        // border-radius: 30px;
      }
    }
  }
}
