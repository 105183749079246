.download {
  margin-left: 20vw;
  margin-top: 10px;
}

@media screen and (min-width: 769px) {
  .resumeholder {
    width: 30vw;
    border: 5px solid #44484a;
    margin-left: 20vw;
    height: fit-content;
    overflow: hidden;
    .mob {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .resumeholder {
    width: fit-content;
    border: 5px solid #44484a;
    margin-left: 20vw;
    height: fit-content;
    overflow: scroll;
  }
  .desk {
    display: none;
  }
  .download {
    width: 120px;
    height: 50px;
    font-size: 12px;
    margin-bottom: 0px;
  }
}
