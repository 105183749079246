.navbar{
  z-index: 4;
  height: 100%;
  width: 100px;
  background-color: #011f4c;
  position: fixed;
  text-align: center;
}

a{
  text-decoration: none;
}

.name:hover{
  cursor: pointer;
}

.name h1{
  margin-top: 3vh;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  color: white;
  font-family: "LemonBold";
  font-size: 50px;
  margin-right: auto;
  margin-left: auto;
}

.last-name{
  display: none;
}

.name span{
  color: #ff3366;
}

img{
  display: inherit;
  margin-top: 10px;
  height: 80px;
  width: 80px;
}

img:hover{
  transform: scale(1.1);
}


