.eduProfile {
  font-family: "Roboto", sans-serif;
  background-color: white;
  border-left: 4px solid #011f4c;
  height: fit-content;
  margin-bottom: 20px;
  line-height: 10px;
  padding: 10px;
}

/* .eduProfileHold{ 
    line-height: 10px;
    padding-top: auto;
    padding-bottom: auto;
    margin-left: 10px;
    margin: 10px;
    border: 1px solid red; */

.para {
  font-size: 12px;
  font-weight: 500;
  color: #616161;
}
.head {
  font-size: 26px;
  font-weight: 500;
  color: #011f4c;
  line-height: 25px;
}
.hhead {
  font-size: 24px;
  letter-spacing: 0.4;
  line-height: 30px;
}
