
.formContainer{
    font-family: "LemonBold";
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    .form{
        display: block;
        margin-left: auto;
        margin-right: auto;
        // width: 50vw;
        ::placeholder{
            font-family: "LemonBold";
        }
        input{
            padding: 8px;
            border: none;
            font-size: 18px;
            border-radius: 2px;
            outline: none;
            width: 100%;
            border: 3px solid #011f4c;
            font-family:Verdana, Geneva, Tahoma, sans-serif;
        }
        input:invalid:focus{
            border: 3px solid #ff3366;
        }
        textarea{
            padding: 8px;
            border: none;
            font-size: 18px;
            outline: none;
            border: 3px solid #011f4c;
            resize: vertical;
            font-family:Verdana, Geneva, Tahoma, sans-serif;
        }
        #email{
            margin-top: 15px;
        }
        #msg{
            width: 100%;
            margin-top: 15px;
            height: 100px;
            border-radius: 2px;
        }

        button{
            color: white;
            text-align: center;
            font-size: 20px;
            background-color: #011f4c;
            border: none;
            cursor: pointer;
            letter-spacing: 1px;
            width: 110px;
            height: 50px;
            padding: 4px;
            margin-top: 20px;
            font-weight: bold;
            font-family: "LemonBold";
        }
        .form-btn:hover{
            background-color: #ff3366;
        }
    }
}
