@import-normalize;
@import "home.css";
@import "navbar.css";
@import "pages.css";
@import "sidebar.css";
@import "loading.css";
@import "navbarmobile.css";
@import "sidebarmobile.css";
@import "responsive.css";
@import "educationProfile.css";
@import "education.css";

body {
  position: relative;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
