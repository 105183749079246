.sidebarmobile{
    font-family: "LemonMedium";
    z-index: 3;
    position: fixed;
    width:0;
    height: 100%;
    background-color: #011f4c;
    overflow-x: hidden;
    transition: 0.4s;
}

.side-listmobile{
    margin-left: min(25vw, 60px);
    margin-top: 2.5vh;
    color: white;
}

.side-listmobile h1{
    color: white;
    margin-bottom: 0px;
}

.side-listmobile h1:hover{
    cursor: pointer;
}

h1:hover .list-letter{
    color: #ff3366;
}

.sidebarmobile hr{
    display:none;
    width:min(130px,120px);
    margin-left: 0px;
    margin-top: 0px;
    border: none;
    background: linear-gradient(to left,#011f4c, #ff3366    );
    height: 2px;
}

.active hr{
    display: block;
}

